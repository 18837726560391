import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { format } from 'date-fns'
import { MDXProvider } from '@mdx-js/react'

import LayoutFullWidth from '../../components/Layout/LayoutFullWidth'
import headerProps from '../../components/propTypes/headerProps'
import {
  Divider,
  AggregatorTemplate,
  PinBlockGroup,
  AggregatorPin4,
  TransitionLink,
} from '../../components'
import { pipe, groupBy, sort, pathOr } from 'ramda'
import styles from './ConveningPageTemplate.module.css'

const propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: headerProps,
        intro: PropTypes.shape({
          title: PropTypes.string,
          text: PropTypes.string,
        }),
      }),
    }),
    events: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.arrayOf(
              PropTypes.shape({
                slug: PropTypes.string,
              }),
            ),
            frontmatter: PropTypes.arrayOf(
              PropTypes.shape({
                title: PropTypes.string,
                startDate: PropTypes.string,
                archive: PropTypes.bool,
                city: PropTypes.string,
                featuredImage: PropTypes.string,
              }),
            ),
          }),
        }),
      ),
    }),
  }),
}

const groupEventsByYear = pipe(
  pathOr([], ['events', 'edges']),
  groupBy(event => event.node.frontmatter.startDate.split('-')[0]),
)

export const ConveningPageTemplate = ({ data }) => {
  const { page } = data
  const { frontmatter } = page
  const { intro } = frontmatter

  const currentYear = new Date().getFullYear()
  const eventsByYear = groupEventsByYear(data)

  const currentYearEvents = pipe(
    pathOr([], [currentYear]),
    sort(
      (a, b) =>
        new Date(a.node.frontmatter.startDate) -
        new Date(b.node.frontmatter.startDate),
    ),
  )(eventsByYear)

  const eventsByMonth = pipe(
    groupBy(event => event.node.frontmatter.startDate.split('-')[1]),
  )(currentYearEvents)

  const breadcrumbs = [
    {
      label: 'Events',
    },
    {
      label: 'Convening for Results',
      url: '/convening',
    },
  ]

  return (
    <MDXProvider>
      <AggregatorTemplate breadcrumbs={breadcrumbs} intro={intro}>
        <Divider thickness={4} />
        {currentYearEvents.length === 0 && (
          <p>There are currently no upcoming events.</p>
        )}
        {Object.keys(eventsByMonth)
          .sort((a, b) => a - b)
          .map((month, i) => {
            const monthName = format(
              new Date(`${currentYear}-${month}-01`),
              'MMMM',
            )
            return (
              <>
                <PinBlockGroup key={i} sectionTitle={monthName} columns={2}>
                  {eventsByMonth[month].map(({ node }, i) => (
                    <AggregatorPin4
                      key={i}
                      title={node.frontmatter.title}
                      thumbnailSrc={node.frontmatter.featuredImage}
                      href={node.fields.slug}
                    >
                      {node.frontmatter.excerpt}...
                    </AggregatorPin4>
                  ))}
                </PinBlockGroup>
                <Divider thickness={4} />
              </>
            )
          })}
        <div className={styles.moreEvents}>
          <TransitionLink
            to="/convening/past-events"
            className="button is-primary"
          >
            Past Events
          </TransitionLink>
          <a
            href="https://www.gsb.uct.ac.za/events"
            target="_blank"
            rel="noreferrer"
            className="button is-primary"
          >
            GSB Events
          </a>
        </div>
      </AggregatorTemplate>
    </MDXProvider>
  )
}

ConveningPageTemplate.propTypes = propTypes

const ConveningPage = ({ data }) => {
  const {
    page: {
      frontmatter: { header },
    },
  } = data
  return (
    <LayoutFullWidth header={header}>
      <ConveningPageTemplate data={data} />
    </LayoutFullWidth>
  )
}

ConveningPage.propTypes = propTypes

export default ConveningPage

export const pageQuery = graphql`
  query ConveningPageTemplate($slug: String!, $currentDate: Date) {
    page: mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        ...headerFields
        ...introFields
      }
    }
    events: allMdx(
      filter: {
        fields: { contentType: { eq: "events" } }
        frontmatter: { startDate: { gte: $currentDate }, archive: { ne: true } }
      }
      limit: 1000
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            startDate
            archive
            city
            excerpt
            featuredImage {
              childCloudinaryMediaImage {
                fixed(width: 150) {
                  base64
                  height
                  src
                  srcSet
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`
